import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "d-flex flex-center flex-column-auto p-10" }
const _hoisted_5 = { class: "d-flex align-items-center fw-bold fs-6" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed",
    style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.getIllustrationsPath('14.png') + ')' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        href: _ctx.webURL,
        class: "mb-12"
      }, [
        _createElementVNode("img", {
          alt: "Logo",
          src: `${_ctx.publicPath}assets/img/logo.jpeg`,
          class: "h-100px"
        }, null, 8, _hoisted_3)
      ], 8, _hoisted_2),
      _createVNode(_component_router_view)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("a", {
          href: _ctx.webURL + '/#about-us',
          class: "text-muted text-hover-primary px-2"
        }, "About " + _toDisplayString(_ctx.appName), 9, _hoisted_6),
        _createElementVNode("a", {
          href: _ctx.webURL + '/core-userguide',
          class: "text-muted text-hover-primary px-2"
        }, "User Guide", 8, _hoisted_7),
        _createElementVNode("a", {
          href: _ctx.webURL + '/blogs',
          class: "text-muted text-hover-primary px-2"
        }, "Blogs", 8, _hoisted_8),
        _createElementVNode("a", {
          href: _ctx.webURL + '/contact-us',
          class: "text-muted text-hover-primary px-2"
        }, "Contact Us", 8, _hoisted_9)
      ])
    ])
  ], 4))
}